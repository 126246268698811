import React from 'react'

export default function SectionFooter() {
  return (
    <footer className="pb-5 pt-5">
      <p className="text-center">
        © 2024 carlosriveradeveloper.cl
      </p>
    </footer>
  )
}
