import React from 'react'

export default function SectionHero(lang) {
  return (
    <>
      <section className="hero sections" id="hero">
        <div className="container-fluid container-lg">
          <div className="hero-title">
            <h1>
              {
                lang.lang === "en" ? `Hi, I'm` : `Hola, soy`
              }       
              <br /> Carlos Rivera       
            </h1>		
            <p>
              {
                lang.lang === "en" 
                ? `Computer engineer with experience in creating web applications with intuitive and dynamic interfaces.` 
                : `Ingeniero en informática con experiencia en creación de aplicaciones web con  interfaces intuitivas y dinámicas.`
              }              
            </p>	
          </div>
        </div>
        <span className="arrow-down material-symbols-rounded">expand_more</span>
      </section>      
    </>
  )
}
