import React, { useContext } from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Helmet} from "react-helmet";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
	  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WHV325SK"
	height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
	
    <BrowserRouter>
      <Routes>
        <Route path="/:en" element={   
          <React.StrictMode>
            <App />
          </React.StrictMode> 
        }/>
        <Route path="/" element={   
          <React.StrictMode>
            <App />
          </React.StrictMode> 
        }/>
      </Routes>
    </BrowserRouter>
  </>
);

reportWebVitals();
