import React from 'react';
import './App.css';
import SectionHeader from './componentes/SectionHeader';
import { Main } from './elementos/Elementos';
import SectionHero from './componentes/SectionHero';
import SectionTecnologias from './componentes/SectionTecnologias';
import SectionTrabajos from './componentes/SectionTrabajos';
import SectionFooter from './componentes/SectionFooter';
import { useParams } from "react-router-dom";

function App() {

  const {en} = useParams();
  let lang = en === undefined ? 'es' : 'en'  
  
  return (
    <>
      <SectionHeader lang={lang}/>
      <Main>
        <SectionHero lang={lang}/>
        <SectionTecnologias lang={lang}/>
        <SectionTrabajos lang={lang}/>
      </Main>
      <SectionFooter />
    </>
  );
}

export default App;
