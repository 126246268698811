import { useEffect, useState } from 'react'
import { db } from '../firebase/firebaseConfig';
import { collection, onSnapshot, query, where, orderBy, limit } from "firebase/firestore";

export default function useObtenerTrabajos() {

  const [trabajos, cambiarTrabajos] = useState([]);
  
  useEffect(() => {

    const consulta = query(
      collection(db, 'trabajos'),
      orderBy('numero', 'desc'),
      limit(12),
      where('visible', '==', 'si')
    );

    const unsuscribe = onSnapshot(consulta, (snapshot) => {
      cambiarTrabajos(snapshot.docs.map((datos) => {
        return {...datos.data(), id: datos.id}
      }));
    })
    
    return unsuscribe;

  }, [])

  return [trabajos];
}
