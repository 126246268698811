import React from 'react'
import { Enlace } from '../elementos/Elementos';
import { Link as Links } from 'react-router-dom';
import { Link, animateScroll as scroll } from "react-scroll";


export default function SectionHeader(lang) {

  const menu = (e) => {
    document.querySelector("#"+e).classList.add("act");
  }

  const openIdioma = (e) => {
    document.querySelector("#"+e).classList.add("act");
  }

  document.documentElement.addEventListener("click", (e) =>{
    if(!e.target.classList.contains("dropdown-toggle")){
      document.querySelector("#idiomaDesktop").classList.remove("act");
      document.querySelector("#idiomaMobile").classList.remove("act");
    }  
    
    if(!e.target.classList.contains("icon-menu"))
    {
      document.querySelector("#menuMobile").classList.remove("act");
    }
  })

  window.onscroll = function () {
    if (window.scrollY >= 50) {
      document.querySelector("#header").classList.add("act");
    }
    else {
      document.querySelector("#header").classList.remove("act");
    }
  }

  return (
    <>
    
      <header id="header">
        <div className="container-fluid container-lg h-100">
          <div className="header-int">
            <div className="redes">
              <Enlace as={Links} to={'https://www.linkedin.com/in/carlos-rivera-619145234/'} target="_blank" className="redes-item" aria-label="Ir a perfil de linkedin">
                <i className="fab fa-linkedin"></i>
              </Enlace>
              <Enlace as={Links} to={'https://github.com/TheCarlosRivera'} target="_blank" className="redes-item" aria-label="Ir a perfil de GitHub">
                <i className="fab fa-github-square"></i>
              </Enlace>
            </div>
            <div className="d-flex">
              <div className="ctn-idioma me-3 mt-1 d-md-none">
              <button
                className="dropdown-toggle"
                type="button"
                onClick={() => openIdioma('idiomaMobile')}>
                { lang === 'en' ? 'Language' : 'Idioma' }
              </button>
                <ul className="dropdownMenu" id="idiomaMobile">
                <li>
                  <Enlace as={Links} to={'/'} className={lang.lang === 'en' ? '' : 'act'}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/portafolio-b562f.appspot.com/o/spain_330557.png?alt=media&token=169001ce-6599-466d-af46-477e2ffc4db7" alt="" />
                      <span className="ms-2">ES</span>
                  </Enlace>
                </li>
                <li>
                  <Enlace as={Links} to={lang.lang === 'en/' ? '' : '/en'} className={lang.lang === 'en' ? 'act' : ''}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/portafolio-b562f.appspot.com/o/united-states_330459.png?alt=media&token=52a2c66a-f293-402a-ac98-ffedecb3ba28" alt="" />
                      <span className="ms-2">EN</span>
                  </Enlace>
                </li>
                </ul>
              </div>
              <div className="menu">
                <span 
                  className="d-md-none icon-menu material-symbols-rounded" 
                  onClick={() => menu('menuMobile')}>
                    menu
                </span>
                <nav className="menuMobile" id='menuMobile'>
                  <ul>
                    <li> 
                      <Link to={'tecnologias'} className="enlaceSeccion">
                        { lang.lang === 'en' ? 'Technologies' : 'Tecnologías' }
                      </Link>
                    </li>
                    <li>
                      <Link to={'trabajos'} className="enlaceSeccion">
                        { lang.lang === 'en' ? 'Works' : 'Trabajos' }
                      </Link>  
                    </li>
                    <li className="d-none d-md-block">
                      <div className="ctn-idioma">
                      <button
                        className="dropdown-toggle"
                        type="button"
                        onClick={() => openIdioma('idiomaDesktop')}>
                        { lang.lang === 'en' ? 'Language' : 'Idioma' }
                      </button>
                      <ul className="dropdownMenu" id="idiomaDesktop">
							          <li>
                          <Enlace as={Links} to={'/'} className={lang.lang === 'en' ? '' : 'act'}>
                            <img src="https://firebasestorage.googleapis.com/v0/b/portafolio-b562f.appspot.com/o/spain_330557.png?alt=media&token=169001ce-6599-466d-af46-477e2ffc4db7" alt="" />
                              <span className="ms-2">ES</span>
                          </Enlace>
                        </li>
                        <li>
                          <Enlace as={Links} to={lang.lang === 'en/' ? '' : '/en'} className={lang.lang === 'en' ? 'act' : ''}>
                            <img src="https://firebasestorage.googleapis.com/v0/b/portafolio-b562f.appspot.com/o/united-states_330459.png?alt=media&token=52a2c66a-f293-402a-ac98-ffedecb3ba28" alt="" />
                              <span className="ms-2">EN</span>
                          </Enlace>
                        </li>
                      </ul>
                      </div>
                    </li>
                  </ul>
                </nav>					
              </div>
            </div>

      
          </div>
        </div>
      </header>    
    </>   
  )
}
