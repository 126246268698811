import styled from 'styled-components';

const Main = styled.main`

`;

const Enlace = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  color: var(--dark);
  background: transparent;
  border: none;
  transition: var(--transitions);
`;


export
{
  Main,
  Enlace
}