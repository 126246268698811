import React from 'react'
import 'animate.css';
import WOW from 'wow.js';
import useObtenerTrabajos from '../hooks/useObtenerTrabajos';

export default function SectionTrabajos(lang) {

  const [trabajos] = useObtenerTrabajos();
  class Title extends React.Component {
    
    componentDidMount () {
      const wow = new WOW();
      wow.init();
    }
    
    render () {
      return (
        <h2 className="title-section wow animate_animated animate__fadeInUp animate__slow" data-wow-offset="150">
        {
          lang.lang === 'en' 
          ? 'Recent works'
          : 'Trabajos recientes'          
        } 
          
        </h2>
      );
    }
  }

  return (
    <>
      <section className="trabajos space-content sections" id="trabajos">
        <div className="container">
          <Title />

          <div className="row g-0 pt-3">

            {
              trabajos.map((dato, index) => {

                const etiqueta = dato.herramientas.split('-');
                const colors = ['red', 'green', 'blue', 'yellow', 'purple', 'gray', 'fucsia'];

                return (
                  <div className="col-12 col-md-6 col-lg-4 wow animate__animated animate__fadeInUp" key={dato.id}> 
                    <div className="card card-work">
                      <div className="card-int">
                        <div className="card-head">
                          <img src={dato.imagen_desktop} alt={dato.titulo} className="img-fluid" />
                        </div>
                        <div className="card-body">
                          <p className="card-body-title">{dato.titulo}</p>
                          <div>
                            {
                              etiqueta.map((e, index) => {                              
                                return (
                                  <span key={e} className={`etiqueta ${colors[Math.floor(Math.random()*6)]}`}>{e}</span>
                                )                              
                              })
                            }
                          </div>
                          <p className="card-body-main">{dato.desarrollado}</p>
                          <a target="_blank" href={dato.url} className="btn mt-4">
                            {
                              lang.lang === 'en' 
                              ? 'Website'
                              : 'Sitio web'          
                            }
                          </a>
                        </div>							
                      </div>
                    </div>
                </div>
                )
                
              })
            }

          </div>
        </div>
      </section>      
    </>
  )
}
