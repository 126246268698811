import React from 'react'
import 'animate.css';
import WOW from 'wow.js';
import useObtenerTecnologias from "../hooks/useObtenerTecnologias";


export default function SectionTecnologias(lang) {

  const [tecnologias] = useObtenerTecnologias();
  
  class Title extends React.Component {
    
    componentDidMount () {
      const wow = new WOW();
      wow.init();
    }
    
    render () {
      return (
        <h2 className="title-section wow animate_animated animate__fadeInUp animate__slow" data-wow-offset="150">
         {
          lang.lang === 'en' 
          ? 'Technologies'
          : 'Tecnologías'          
         } 
        </h2>
      );
    }
  }

  return (
    <>
      <section className="tecnologias space-content sections" id="tecnologias">
        <div className="container">		
          <Title />
          <div className="tecnologias-ctn pt-5">
            {
              tecnologias.map((dato, index) => {
                return(                
                  <div className="card-item wow animate_animated zoomIn animate__slow" key={dato.id}>
                    <div className="card-item-head">
                      <img src={`${dato.imagen}`} alt={dato.nombre} className="img-fluid" />
                    </div>
                    <div className="card-item-body">
                      <p>{dato.nombre}</p>
                    </div>
                  </div>                                
                )                
              })
            }
          </div>
        </div>		
      </section>     
    </>
  )
}
