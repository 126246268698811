import { useEffect, useState } from 'react'
import { db } from '../firebase/firebaseConfig';
import { collection, onSnapshot, query, where } from "firebase/firestore";

export default function useObtenerTecnologias() {

  const [tecnologias, cambiarTecnologias] = useState([]);
  
  useEffect(() => {

    const consulta = query(
      collection(db, 'conocimientos'),
      where('disponible', '==', 'si')
    );

    const unsuscribe = onSnapshot(consulta, (snapshot) => {
      cambiarTecnologias(snapshot.docs.map((datos) => {
        return {...datos.data(), id: datos.id}
      }));
    })
    
    return unsuscribe;

  }, [])

  return [tecnologias];
}
